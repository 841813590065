import React, {useState} from 'react'
import {MarkdownContent} from './Content'

export default function ReadMore({content}) {
  const [open, setOpen] = useState(false)

  return (
    <div className='is-flex is-flex-direction-column'>
      {open && <MarkdownContent content={content} className='' />}
      <button
        type='button'
        onClick={() => setOpen((prev) => !prev)}
        className={`button is-ghost has-text-left ${open ? 'mt-2' : ''}`}
      >
        {open ? 'Read Less...' : 'Read More...'}
      </button>
    </div>
  )
}
