import React from 'react'
import {navigate} from 'gatsby-link'
import {MarkdownContent} from '../components/Content'
import {FaPhone} from 'react-icons/fa'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isValidated: false}
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <div className='container'>
        <div className='content'>
          <h1 className='title is-1 has-text-white'>Contact</h1>
          {this.props.content && (
            <MarkdownContent
              content={this.props.content}
              className='has-text-white'
            />
          )}
          {this.props.phoneNumber && (
            <div className='phone-number has-text-white mb-6'>
              <a href={`tel:${this.props.phoneNumber}`} className='box pr-5'>
                <span className='icon'>
                  <FaPhone />
                </span>
                {this.props.phoneNumber}
              </a>
            </div>
          )}
          <form
            name='contact'
            method='post'
            action='/contact/thanks/'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type='hidden' name='form-name' value='contact' />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name='bot-field' onChange={this.handleChange} />
              </label>
            </div>
            <div className='field'>
              <label className='label has-text-white' htmlFor={'name'}>
                Your name
              </label>
              <div className='control'>
                <input
                  className='input'
                  type={'text'}
                  name={'name'}
                  onChange={this.handleChange}
                  id={'name'}
                  required={true}
                />
              </div>
            </div>
            <div className='field'>
              <label className='label has-text-white' htmlFor={'email'}>
                Email
              </label>
              <div className='control'>
                <input
                  className='input'
                  type={'email'}
                  name={'email'}
                  onChange={this.handleChange}
                  id={'email'}
                  required={true}
                />
              </div>
            </div>
            <div className='field'>
              <label className='label has-text-white' htmlFor={'phone'}>
                Phone Number
              </label>
              <div className='control'>
                <input
                  className='input'
                  type={'tel'}
                  name={'phone'}
                  onChange={this.handleChange}
                  id={'phone'}
                  required={false}
                />
              </div>
            </div>
            <div className='field'>
              <label className='label has-text-white' htmlFor={'message'}>
                Message
              </label>
              <div className='control'>
                <textarea
                  className='textarea'
                  name={'message'}
                  onChange={this.handleChange}
                  id={'message'}
                  required={true}
                  maxLength={500}
                />
              </div>
            </div>
            <div className='field'>
              <button className='theme-btn' type='submit'>
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
