import React from 'react'
import {graphql} from 'gatsby'
import {AnchorLink} from 'gatsby-plugin-anchor-links'

import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import ContactForm from '../components/ContactForm'
import {MarkdownContent} from '../components/Content'
import BlogRoll from '../components/BlogRoll'
import ReadMore from '../components/ReadMore'

export const IndexPageTemplate = ({
  hero,
  offerings,
  about,
  pricing,
  theWork,
  blog,
  contact,
}) => {
  return (
    <div>
      <section id='top' className='hero is-dark is-fullheight-with-navbar'>
        <div
          className='hero-body is-align-items-stretch is-relative'
          style={{
            overflow: 'hidden',
          }}
        >
          <div className='hero-image-container'>
            <PreviewCompatibleImage
              imageInfo={{
                image: hero.backgroundImage,
                alt: `floating lilly pad`,
                className: 'hero-image',
              }}
            />
          </div>
          <div className='container is-flex is-flex-direction-column is-justify-content-space-around'>
            <div className='is-flex is-flex-direction-row'>
              <div>
                <h1 className='title'>{hero.heading}</h1>
                <h2 className='subtitle is-3 is-size-5-mobile'>
                  {hero.subheading}
                </h2>
              </div>
            </div>
            <div className='emphasis-words mr-4'>
              {hero.quote.map((line, idx) => (
                <div className='emphasis-word' key={idx}>
                  {line}
                </div>
              ))}
            </div>
            {hero.ctaBtnText && hero.ctaBtnLink && (
              <div>
                <AnchorLink
                  to={hero.ctaBtnLink}
                  title={hero.ctaBtnText}
                  className='theme-btn mt-3 stripped slide-in-from-left'
                  stripHash
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <section id='the-work' className='section'>
        <div className='container'>
          <div className='the-work-container'>
            <div className='left p-5'>
              <h1 className='title is-1 has-text-white'>The Work</h1>
              <MarkdownContent
                content={theWork.content}
                className='has-text-white'
              />
              {theWork.ctaBtnText && theWork.ctaBtnLink && (
                <div className='cta-btn'>
                  <AnchorLink
                    to={theWork.ctaBtnLink}
                    title={theWork.ctaBtnText}
                    className='theme-btn mt-3 stripped slide-in-from-left'
                    stripHash
                  />
                </div>
              )}
            </div>
            <div className='right'>
              <PreviewCompatibleImage
                imageInfo={{
                  image: theWork.backgroundImage,
                  alt: `image associated with ${theWork.title}`,
                  className: 'the-work-img',
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section id='offerings' className='section'>
        <div className='container is-max-desktop'>
          <div className='columns'>
            <div className='column is-full'>
              <h1 className='title is-1'>Offerings</h1>
            </div>
          </div>
          <div className='offerings-list-container'>
            {offerings.map((offering, idx) => (
              <div className='offering' key={idx}>
                <div className='offering-img-container'>
                  <figure className='is-rounded'>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: offering.image,
                        alt: `image associated with ${offering.title}`,
                      }}
                    />
                  </figure>
                </div>
                <div className='offering-content-container'>
                  <h1 className='title is-3 accent-underline'>
                    <MarkdownContent content={offering.title} />
                  </h1>
                  <h2 className='subtitle is-6'>
                    <MarkdownContent content={offering.subtitle} />
                  </h2>
                  <MarkdownContent content={offering.description} />
                  {offering.readMoreContent && (
                    <ReadMore content={offering.readMoreContent} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id='about' className='section is-brown has-text-white'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-full'>
              <h1 className='title is-1 has-text-white'>{about.heading}</h1>
            </div>
          </div>
          <figure className='is-rounded'>
            <PreviewCompatibleImage
              imageInfo={{
                image: about.image,
                alt: about.heading,
                className: 'is-rounded is-wrap-around-circle about-img',
              }}
            />
          </figure>
          <MarkdownContent content={about.content} className='about-content' />
        </div>
      </section>
      {pricing.hideSection !== true && (
        <section id='details' className='section'>
          <div className='container is-max-desktop'>
            <div className='columns'>
              <div className='column is-full'>
                <h1 className='title is-1 accent-underline is-full'>
                  {pricing.heading}
                </h1>
                <MarkdownContent content={pricing.details} />
              </div>
            </div>
            {pricing.offerings?.map((offering, idx) => (
              <div className='mb-6' key={idx}>
                <h2 className='is-size-4 has-text-weight-bold mb-3'>
                  {offering.title}
                </h2>
                {offering.description && (
                  <MarkdownContent content={offering.description} />
                )}
                <div className='pricing-cards-container mt-2'>
                  {offering.prices?.map((price, idx) => (
                    <div className='pr-3 pb-2' key={idx}>
                      <div className='card px-4 py-1'>
                        <div className='card-content is-size-6 py-1'>
                          <div className='content is-flex'>
                            <div className='pr-4'>{price.name}:</div>
                            <div className=''>{price.price}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className='columns'>
              <div className='column is-full'>
                <MarkdownContent content={pricing.bottomDetails} />
              </div>
            </div>
            {pricing.ctaBtnText && pricing.ctaBtnLink && (
              <div className='mt-5'>
                <AnchorLink
                  to={pricing.ctaBtnLink}
                  title={pricing.ctaBtnText}
                  className='theme-btn mt-3 stripped slide-in-from-left'
                  stripHash
                />
              </div>
            )}
          </div>
        </section>
      )}
      {blog.hideSection !== true && (
        <section
          id='blog'
          className='section is-parallax'
          style={{
            backgroundImage: `url(${
              !!blog.backgroundImage.childImageSharp
                ? blog.backgroundImage.childImageSharp.fluid.src
                : blog.backgroundImage
            })`,
          }}
        >
          <div className='container'>
            <h1 className='title is-1 has-text-white'>{blog.heading}</h1>
            <p className='mb-5 has-text-white'>{blog.subheading}</p>
            <BlogRoll featuredPostsOnly={true} />
            <AnchorLink
              to='/blog'
              title={blog.btnText}
              className='button mt-4 stripped'
              stripHash
            />
          </div>
        </section>
      )}
      <section id='contact' className='section is-brown'>
        <ContactForm
          content={contact.content}
          phoneNumber={contact.phoneNumber}
        />
      </section>
    </div>
  )
}

const IndexPage = ({data}) => {
  const {frontmatter} = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        hero={frontmatter.hero}
        about={frontmatter.about}
        pricing={frontmatter.pricing}
        offerings={frontmatter.offerings}
        theWork={frontmatter.theWork}
        blog={frontmatter.blog}
        contact={frontmatter.contact}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        hero {
          heading
          subheading
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          ctaBtnText
          ctaBtnLink
          quote
        }
        theWork {
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content
          title
          ctaBtnText
          ctaBtnLink
        }
        offerings {
          title
          subtitle
          description
          readMoreContent
          image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        about {
          heading
          content
          image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        pricing {
          hideSection
          heading
          details
          offerings {
            title
            description
            prices {
              name
              price
            }
          }
          bottomDetails
          ctaBtnText
          ctaBtnLink
        }
        blog {
          hideSection
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          btnText
          heading
          subheading
        }
        contact {
          content
          phoneNumber
        }
      }
    }
  }
`
